import React from "react";
import { graphql } from "gatsby";
import { Disqus } from "gatsby-plugin-disqus";

import SEO from "../components/seo";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  location,
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { id, frontmatter, html } = markdownRemark;

  let disqusConfig = {
    url: `${location.origin}${location.pathname}`,
    identifier: id,
    title: frontmatter.title,
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title={frontmatter.title} blogPost={frontmatter} />
      <article className="post">
        <header>
          <h1>
            {frontmatter.title}
            <small>Posted on: {frontmatter.date}</small>
          </h1>
        </header>

        {frontmatter.twil ? (
          <div className="twil-excerpt no-md">
            <p>
              <strong>T</strong>his <strong>W</strong>eek <strong>I</strong>{" "}
              <strong>L</strong>earned
            </p>
          </div>
        ) : null}

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />

        {frontmatter.github ? (
          <div className="github-ribbon">
            <a href="{frontmatter.github}">
              {frontmatter.githubText ? frontmatter.githubText : "Fork me "}
              on Github
            </a>
          </div>
        ) : null}

        <footer>
          <Disqus config={disqusConfig} />
        </footer>
      </article>
    </>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      id
      frontmatter {
        className
        date(formatString: "MMMM DD, YYYY")
        excerpt
        github
        githubText
        status
        styles
        tags
        title
        twil
      }
    }
  }
`;
